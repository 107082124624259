import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import formatDate from "../../utils/formatDate";

function BlogList() {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "blog" } } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              date
              author
              image
            }
            fields {
              collection
            }
            excerpt
          }
        }
      }
    }
  `);

  return (
    <div className="mt-16">
      {query.allMarkdownRemark.edges.map(({ node }, i) => {
        const { path, title, date, author, image } = node.frontmatter;

        return (
          <Link
            to={path}
            key={path}
            className="text-center sm:text-left mb-12 sm:mb-6 md:mb-12 block sm:flex no-underline cursor-pointer"
          >
            <div className="w-32 mx-auto sm:ml-0 mb-2 sm:mr-8 sm:mb-0">
              <img className="w-full" src={`/images/${image}.svg`} alt="" />
            </div>
            <div>
              <h2>{title}</h2>
              <div className="mb-4 text-black">
                {formatDate(date)} • {author}
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

export default BlogList;
