import React from "react";

function Hero({ children, image }) {
  return (
    <div
      className="sm:h-56 flex items-end"
      style={{
        backgroundImage: [
          "linear-gradient(20deg, rgba(255, 255, 241, 1) 20%, rgba(255, 255, 255, 0) 40%)",
          image ? `url(/images/${image}.svg)` : ""
        ].join(", "),
        backgroundPosition: "bottom right",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        minHeight: "8rem"
      }}
    >
      <div className="hero-content text-2xl sm:text-4xl font-bold leading-tight text-indigo-800 text-shadow">
        {children}
      </div>
    </div>
  );
}

export default Hero;
