import moment from "moment";

export default function formatDate(
  dateString,
  inputFormat = "YYYY-MM-DD",
  outputFormat = "D.M.YYYY"
) {
  const formattedDate = moment(dateString, inputFormat);
  return formattedDate.format(outputFormat);
}
