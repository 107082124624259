import React from "react";
import Layout from "../components/layout/Layout";

import "./index.css";
import Hero from "../components/layout/Hero";
import SEO from "../components/seo/SEO";
import BlogList from "../components/lists/BlogList";

export default function Blogi() {
  return (
    <>
      <SEO title="Blogi" description="" />
      <Layout>
        <Hero image="dev_focus">Blogi</Hero>

        <BlogList />
      </Layout>
    </>
  );
}
